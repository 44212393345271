import 'jquery-lazy'

const lazyImg = () => $('.lazy').Lazy({
    scrollDirection: 'vertical',
    effect: 'fadeIn',
    visibleOnly: true,
    onError: function (element) {
        element.attr('src', '/img/noimg.png')
        element.addClass('noimg')
    }
});

export default lazyImg
