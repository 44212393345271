const recaptcha = () => grecaptcha.ready(function () {
    $('form').each(function () {
        $('button[type="submit"]').prop('disabled', false)
        $('[data-gcaptcha-preloader]').remove()

        let input = $(this).find('input[name="recaptcha"]')
        if (input.length) {
            grecaptcha.execute('6LdR2i4iAAAAAPTUUToKf5BqEkjlUZHqM7x1XZms').then(function (token) {
                input.val(token)
            });
        }
    })
})

export default recaptcha
