import notif from "./notif";

const liveSearch = () => {
    const searchInput = $("[data-live-search]")
    const route = searchInput.data('route');
    const resultContainer = $('[data-live-search-results]');
    const submitBtn = $('[data-search-preloader]')
    const preloader = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="visually-hidden">Loading...</span>';

    searchInput.keyup(function (e) {
        const q = $(this).val()

        if (e.keyCode === 13) return false

        if (q.length < 2) {
            resultContainer.fadeOut("fast", () => resultContainer.html(''));
            return false
        }

        submitBtn.html(preloader)

        clearTimeout(window.liveSearchTimer);
        window.liveSearchTimer = setTimeout(function () {
            axios
                .get(route, {params: {q: q}})
                .then(r => {
                    resultContainer.html(r.data)
                    resultContainer.fadeIn("fast")
                })
                .catch(err => {
                    if (err.response) {
                        notif.error(err.response.data.message)
                    } else {
                        notif.error('Не удалось выполнить запрос')
                    }
                })
                .finally(() => {
                    submitBtn.html('<i class="bi bi-search"></i>')
                })
        }, 500)
    })

    $(window).click(e => {
        resultContainer.fadeOut("fast", () => resultContainer.html(''));
    })

    resultContainer.click(e => {
        e.stopPropagation()
    })
}

export default liveSearch
